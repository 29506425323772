import React from 'react';
import { merge } from '../../utils/functional';
import { colors } from '../colors';
import NewsletterSignup from '../newsletter_signup';
import * as styles from './signup_ribbon.module.css';


const TextSection = ({ className, style }) => (

    <div
        className={ [styles.text, className].join(" ") }
        style={ style } >

        <span>Start for free</span>
    </div>
);

const Spacer = ({ className, style }) => (
    <div
        className={ [styles.spacer, className].join(" ") }
        style={
            merge({ borderColor: colors.darkBackgroundGrey },
                  style)
        } />
);


const SignupSection = () => (
    <div className={ styles.signup } >

        <div className={ styles.text }>
            Get one full market shift in your inbox every week
        </div>

        <div className={ styles.space } />
        
        <div className={ styles.form } >
            
            <NewsletterSignup
                color={ colors.whiteOff }
                fontSize='14px'
                fontWeight='400'
                height='40px'
                padding='4px 0 0'
                placeholder={ 'Enter your email' } />
            
        </div>
        
    </div>
);


export const SignupRibbon = () => (
    <div
        className={ styles.container }
        style={{
            backgroundColor: colors.black,
            color: colors.whiteOff
        }} >

        <TextSection />
        <Spacer className={ styles.first } />
        <SignupSection />
        <Spacer className={ `${ styles.wideOnly } ${ styles.second }` } />
        <TextSection
            className={ styles.wideOnly } />
        
    </div>
);
