import React, { useEffect } from 'react';
import { graphql, Link } from "gatsby";
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import Layout from '../components/layout';

import { dissoc, merge } from '../utils/functional';
import { refreshSessionId } from '../utils/session_id';
import { rhythm } from '../utils/typography';

import { hr, vr } from '../components/brief/lines';
import { colors } from '../components/colors';
import { joinButton } from '../components/join_button';
import { SignupRibbon } from '../components/public_home/signup_ribbon';

import image from './about/mobile_device.png';
import image_adam from "./about/adam.jpg";
import image_dylan from "./about/dylan.jpg";
import image_eric from "./about/eric.jpg";
import image_tam from "./about/tam.jpg";

import * as cssStyles from './about.module.css';

import { RenderHead } from "../components/head";
export const Head = () => RenderHead({ title: "About Us" });



// width: 100% is required for IE to honor the .container bounds

const styles = {
    
    heading: {
        width: '100%',
        fontFamily: '"Overpass", sans-serif',
        fontSize: '28px',
        fontWeight: 'bold',
        lineHeight: '1.07',
        color: colors.black,
        textAlign: 'center'
    },

    subHeading: {
        width: '100%',        
        fontFamily: '"Overpass", sans-serif',
        fontSize: '20px',
        fontWeight: 'bold',
        lineHeight: '1.5',
        color: colors.black
    },

    subSubHeading: {
        width: '100%',        
        fontSize: '16px',
        lineHeight: '1.75',
        letterSpacing: '0.16px',
        color: colors.black
    },
    
    bulletText: {
        width: '100%',        
        margin: '26px 0 0',
        fontFamily: '"Overpass", sans-serif',
        fontSize: '18px',
        fontWeight: 'bold',
        lineHeight: '1.39',
        color: colors.green,
        textAlign: 'center'
    },
    
    bulletCircle: {
        width: '8px',
        height: '8px',
        margin: `26px 0 0`,
        background: colors.lightestGrey,
        borderRadius: '50%',
        textAlign: `center`
    },
    
    body: {
        width: '100%',
        fontSize: '15px',
        lineHeight: '1.87',
        letterSpacing: '0.15px',
        color: colors.black
    },

    link: {
        textDecoration: 'none',
        borderBottom: 'solid 1px'
    }
};





//
// public or private only sections

const originType = process.env.ORIGIN_TYPE;
const expiredContent = originType === 'expired';
const privateContent = originType === 'private';
const shiftsEmailOnlyContent = originType === 'shifts-email-only';
const memberContent = privateContent || shiftsEmailOnlyContent;


const joinButtonFor = ({ content, to, subscriptionType }) => {

    if ( subscriptionType === 'ALL' && privateContent )
        return ( <div style={{ margin: `0 0 95px` }} />);

    if ( subscriptionType === 'SHIFTS-EMAIL-ONLY' && memberContent )
        return ( <div style={{ margin: `0 0 95px` }} />);
    

    var c = content;
    if ( expiredContent ) content = 'Renew Membership';
    else if ( shiftsEmailOnlyContent ) content = 'Upgrade to All-Access Member';

    var t = to;
    if ( expiredContent || shiftsEmailOnlyContent )
        t = '/account';

    const props = { content: c,
                    to: t,
                    style: { padding: `10px 27px 8px`, fontSize: `16px` } };
    
    return (
        <div
            className={ cssStyles.newsletterSection }
            style={{ margin: `25px 0px 95px` }} >

            { joinButton(props) }
            
        </div>
    );
};




const contentBySection = ({ name, userFields }) => {

    const numShifts = userFields.shifts_researched;
    
    if ( privateContent )
        return {
            "member":
            ["As a Member, you have unlimited access to our deeply researched 6Pages Briefs, including Industry Reviews and Special Editions, covering the most important market shifts emerging from recent signals.",
             "As a Member, you have unlimited online access to the full 6Pages Repository of " + numShifts + " market shifts."],
            
            "shifts":
            ["3 Shifts Email Subscribers get our weekly email with all 3 of the top market shifts that decision-makers should know about every week – delivered straight to your inbox.",
             "3 Shifts Email Subscribers do not have online access to the 6Pages Repository."]
        }[name];

    else if ( shiftsEmailOnlyContent )
        return {
            "member":
            ["All-Access Members get unlimited access to our deeply researched 6Pages Briefs, including Industry Reviews and Special Editions, covering the most important market shifts emerging from recent signals.",
             <span>All-Access Members get unlimited online access to the full 6Pages Repository of 
                 <Link
                     style={ merge( styles.link, { color: colors.green, marginLeft: `3px` }) }
                     to={ '/search' } >{ numShifts } market shifts</Link>.</span>],
            
            "shifts":
            ["As a 3 Shifts Email Subscriber, you get our weekly email with all 3 of the top market shifts that decision-makers should know about every week – delivered straight to your inbox.",
             "3 Shifts Email Subscribers do not have online access to the 6Pages Repository."]
        }[name];

    // public
    return {
        "member":
        ["All-Access Members get unlimited access to our deeply researched 6Pages Briefs, including Industry Reviews and Special Editions, covering the most important market shifts emerging from recent signals.",
         <span>All-Access Members get unlimited online access to the full 6Pages Repository of 
             <Link
                 style={ merge( styles.link, { color: colors.green, marginLeft: `3px` }) }
                 to={ '/search' } >{ numShifts } market shifts</Link>.</span>],
        
        "shifts":
        ["3 Shifts Email Subscribers get our weekly email with all 3 of the top market shifts that decision-makers should know about every week – delivered straight to your inbox.",
         "3 Shifts Email Subscribers do not have online access to the 6Pages Repository."]
    }[name];
};


const ContentBySectionElem = ({ content, nth }) => {
    
    return (
        <div
            style={
                merge(styles.body,
                      { margin: `5px 0 0` },
                      nth === 1 ? { fontStyle: `italic` } : {}
                     )} >
            { content }
        </div>
    );
};




//
// Bio

const bioDatums = [
    { name: 'Tam Thao Pham',
      title: 'CEO & Editor-In-Chief',
      image: image_tam,
      body: 'Tam is an advisor to early-stage startups and angel investor. She spent over a decade at Deloitte focused on strategy, M&A, and innovation leadership roles. She built and ran Deloitte Global Innovation’s Sensing function for 3 years, publishing the Line of Sight briefing for 3,000+ senior leaders. She holds an MBA from the University of Chicago, a Master of Science in Organisational & Social Psychology from the London School of Economics, and a Bachelor of Science in Mathematics of Computation from UCLA.',
      social: {
          linkedin: 'https://www.linkedin.com/in/tamthaopham/',
          email:    'tam@6pages.com' }},
    { name: 'Dylan Hannes',
      title: 'Head of Content',
      image: image_dylan,
      body: 'Dylan spent 5 years at Deloitte in strategy consulting and innovation. He advised senior executive leadership on new market shifts impacting firm innovation priorities as an author for the Line of Sight publication. He also designed and managed a crowdsourced market-intelligence function.',
      social: {
          linkedin: 'https://www.linkedin.com/in/dylan-hannes-54198a47/',
          email:    'dylan@6pages.com' }},
    { name: 'Eric Thompson',
      title: 'Head of Product',
      image: image_eric,
      body: 'Eric spent 8 years in strategy consulting & innovation at Deloitte. He built and led a market intelligence service advising Fortune 500 clients on emerging trends and led a similar function serving 500+ Deloitte practitioners. He was also Product Manager for Bridge, a matchmaking platform for startups and enterprises.',
      social: {
          linkedin: 'https://www.linkedin.com/in/eric-thompson-958a848/',
          email:    'eric@6pages.com' }},
    { name: 'Adam Tait',
      title: 'CTO',
      image: image_adam,
      body: 'Previously, Adam was a senior manager and principal software engineer at Walmart Labs working on distributed systems and big data. He has also held engineering roles at Amazon and various startups. He earned a Bachelors in Software Engineering from the University of Waterloo.',
      social: {
          linkedin: 'https://www.linkedin.com/in/adamtait/',
          email:    'adam@6pages.com' }}
];


const Bio = (data) => {
    return (
        <div
            className={ cssStyles.bio }
            style={{
                display: `flex`,
                flexDirection: `column`
            }} >

            <div
                style={{ width: `100%` }} >
                { hr() }
            </div>

            <div
                className={ cssStyles.bioContent } >

                <div
                    style={{ flex: `0 0 200px` }} >
                    <img
                        alt={ data.name }
                        src={ data.image }
                        style={{
                            margin: `0`,
                            width: `200px`,
                            height: `200px`,
                            objectFit: `contain`
                        }} />
                </div>

                <div className={ cssStyles.bioContentSpacer } />
                
                <div
                    className={ cssStyles.bioContentMain }
                    style={{
                        display: `flex`,
                        flexDirection: `column`,
                        justifyContent: `flex-start`,
                        alignItems: `flex-start`
                    }} >

                    <div
                        style={ styles.subHeading } >
                        { data.name }
                    </div>

                    <div
                        style={ merge( styles.body,
                                       { fontStyle: 'italic' })} >
                        { data.title }
                    </div>

                    <div
                        className={ cssStyles.bioContentMainBody }
                        style={ styles.body } >
                        { data.body }
                    </div>
                    
                </div>

                <div className={ cssStyles.bioContentSpacerTwo } />
                
                <div
                    className={ cssStyles.bioConnect }
                    style={{
                        display: `flex`,
                        justifyContent: `flex-start`,
                        alignItems: `flex-start`
                    }} >

                    <div
                        className={ cssStyles.bioConnectLabel }
                        style={ dissoc( styles.body, ['width']) } >
                        Connect
                    </div>

                    <div
                        className={ cssStyles.bioConnectIcons }
                        style={{
                            display: `flex`,
                            flexDirection: `row`,
                            justifyContent: `flex-start`,
                            alignItems: `flex-start`
                        }} >

                        <OutboundLink
                            style={{
                                boxShadow: `none`,
                                textDecoration: `none`
                            }}
                            href={ data.social.linkedin }
                            target="_blank"
                            rel="noopener noreferrer" >
                            <i
                                className={ "fab fa-lg fa-linkedin-in" }
                                style={{ color: colors.black }} ></i>
                        </OutboundLink>
                        
                        <OutboundLink
                            style={{
                                margin: `0 0 0 35px`,
                                boxShadow: `none`,
                                textDecoration: `none`
                            }}
                            href={ 'mailto:' + data.social.email } >
                            <i
                                className={ "fas fa-lg fa-envelope" }
                                style={{ color: colors.black }} ></i>
                        </OutboundLink>
                        
                    </div>

                </div>
                
            </div>
            
        </div>
    );
};



//
// About


const About = ({ data, location }) => {

    const userFields = data.allUserFieldsJson.edges[0].node;
    
    useEffect(() => { refreshSessionId(); }, []);
    
    return (
        <Layout
            location={ location }
            removeHorizontalPadding={ true } >

            <div
                style={{
                    width: `100%`,
                    overflow: `hidden`,
                    display: `flex`,
                    flexDirection: `column`,
                    justifyContent: `flex-start`,
                    alignItems: `center`
                }} >

                <div
                    className={ cssStyles.container }
                    style={{
                        display: `flex`,
                        flexDirection: `column`,
                        justifyContent: `flex-start`,
                        alignItems: `center`
                    }} >

                    <div
                        style={
                            merge(styles.heading,
                                  { margin: `${rhythm(4.5)} 0 0` })
                        } >
                        What we do
                    </div>

                    <div
                        style={
                            merge(styles.body,
                                  {
                                      margin: `10px 0 0`,
                                      textAlign: `center`
                                  })
                        } >
                        6Pages is a new kind of market-intelligence service that helps professional decision-makers make better and faster decisions in response to far-reaching shifts in business & tech. We scan thousands of news pieces, identify and connect signals of change, and turn them into deeply researched briefs each week on what’s happening and what it means.
                    </div>

                    <div
                        style={
                            merge(styles.heading,
                                  { margin: `${rhythm(4)} 0 0` })
                        } >
                        What makes us different
                    </div>

                    <div
                        style={
                            merge(styles.body,
                                  {
                                      margin: `10px 0 0`,
                                      textAlign: `center`
                                  })
                        } >
                        Our subscription service offers the same level of quality as leading project-based advisory firms at an accessible price point. 6Pages briefs address needs unmet by outdated, expensive industry reports and click-driven news articles short on context.
                    </div>


                    <div
                        className={ cssStyles.bullets }
                        style={{
                            display: `flex`,
                            flexDirection: `column`,
                            justifyContent: `flex-start`,
                            alignItems: `center`
                        }} >
                        
                        <div
                            style={ styles.bulletText } >
                            Written narrative informed by intensive research
                        </div>

                        <div
                            style={ styles.bulletCircle } />

                        <div
                            style={ styles.bulletText } >
                            Forward-looking perspectives on market shifts
                        </div>

                        <div
                            style={ styles.bulletCircle } />

                        <div
                            style={ styles.bulletText } >
                            Continuous market scanning and curation
                        </div>

                        <div
                            style={ styles.bulletCircle } />

                        <div
                            style={ styles.bulletText } >
                            Connections across a broad landscape of markets & technologies
                        </div>

                    </div>
                    
                </div>

                
                <img
                    className={ cssStyles.image }
                    src={ image }
                    alt={ `6Pages mobile` } />

                
                <div
                    className={ cssStyles.breakBox }
                    style={{
                        background: colors.lightGrey
                    }} >

                    <div
                        style={ styles.heading } >
                        What we offer
                    </div>


                    <div
                        className={ `${ cssStyles.flex } ${ cssStyles.columnToRow }` }
                        style={{ padding: `45px 0px` }} >

                        <div
                            className={ cssStyles.noNarrowScreen }
                            style={{ flex: `1 1 50px`, }} />
                        
                        <div
                            className={ cssStyles.breakBoxFirst }
                            style={{
                                flexGrow: `0`,
                                flexShrink: `1`
                            }} >

                            
                            <div
                                style={ styles.subHeading } >
                                All-Access Membership
                            </div>
                            

                            {
                                contentBySection({ name: "member", userFields })
                                    .reduce( (acc, content) => acc.concat( ContentBySectionElem({ content, nth: acc.length }) ), [] )
                            }
                            
                            { joinButtonFor({ content: 'Become a Member', to: '/newmember', subscriptionType: 'ALL' }) }
                            
                            
                            <div
                                style={ styles.subHeading } >
                                3 Shifts Email Subscription
                            </div>
                            
                            {
                                contentBySection({ name: "shifts", userFields })
                                    .reduce( (acc, content) => acc.concat( ContentBySectionElem({ content, nth: acc.length }) ), [] )
                            }

                            { joinButtonFor({ content: 'Become a Subscriber', to: '/newsubscriber', subscriptionType: 'SHIFTS-EMAIL-ONLY' }) }
                            
                        </div>

                        <div
                            className={ cssStyles.noNarrowScreen }
                            style={{ flex: `1 1 50px`, }} />
                        
                        <div
                            className={ cssStyles.noNarrowScreen }
                            style={ memberContent ? { height: `375px` } : { height: `550px` } } >
                            { vr() }
                        </div>

                        <div
                            className={ cssStyles.noNarrowScreen }
                            style={{ flex: `1 1 50px` }} />

                        <div
                            className={ cssStyles.breakBoxSecond }
                            style={{
                                flexGrow: `0`,
                                flexShrink: `1`
                            }} >

                            <div
                                style={ styles.subHeading } >
                                Follow us
                            </div>
                            
                            <div
                                style={{
                                    margin: `10px 0 0`,
                                    display: `flex`,
                                    flexDirection: `row`,
                                    justifyContent: `flex-start`,
                                    alignItems: `center`
                                }} >
                                <OutboundLink
                                    style={{
                                        boxShadow: `none`,
                                        textDecoration: `none`
                                    }}
                                    href="https://twitter.com/6PagesInc"
                                    target="_blank"
                                    rel="noopener noreferrer" >
                                    <i
                                        className={ "fab fa-lg fa-twitter" }
                                        style={{ color: colors.black }} ></i>
                                </OutboundLink>
                                <OutboundLink
                                    style={{
                                        margin: `0 0 0 50px`,
                                        boxShadow: `none`,
                                        textDecoration: `none`
                                    }}
                                    href="https://www.linkedin.com/company/6pagesinc"
                                    target="_blank"
                                    rel="noopener noreferrer" >
                                    <i
                                        className={ "fab fa-lg fa-linkedin-in" }
                                        style={{ color: colors.black }} ></i>
                                </OutboundLink>
                            </div>
                            
                        </div>

                        <div
                            className={ cssStyles.noNarrowScreen }
                            style={{ flex: `1 1 50px` }} />
                        
                    </div>
                    
                </div>

                { memberContent ? <div /> : SignupRibbon() }

                <div
                    className={ cssStyles.container }
                    style={{
                        display: `flex`,
                        flexDirection: `column`,
                        justifyContent: `flex-start`,
                        alignItems: `center`
                    }} >
                    
                    <div
                        style={
                            merge(styles.heading,
                                  { margin: `${rhythm(4)} 0 0` })
                        } >
                        Team
                    </div>

                    <div
                        style={
                            merge(styles.body,
                                  {
                                      margin: `10px 0 0`,
                                      textAlign: `center`
                                  })
                        } >
                        We’re advisors, not journalists. The team behind 6Pages collectively brings decades of experience from relevant backgrounds – strategy, innovation, consulting, M&A, entrepreneurship, angel investing, data science, and software development. Most recently, the 6Pages team spent 3 years operating a function in a large top-tier professional services firm, scanning the marketplace and authoring executive briefs for thousands of senior executives and their teams globally.
                    </div>

                </div>

                <div
                    className={ cssStyles.containerBios } >
                    
                    { bioDatums.map(Bio) }

                    <div
                        style={{ width: `100%` }} >
                        { hr() }
                    </div>
                    
                </div>

                
                <div
                    style={{
                        width: `1px`,
                        height: `100px`,
                        background: `transparent`
                    }} />
                
            </div>
            
        </Layout>
    );
};


export default About;



export const pageQuery = graphql`
  query {
    allUserFieldsJson {
      edges {
        node {
          shifts_researched
        }
      }
    }
  }
`;
