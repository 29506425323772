// extracted by mini-css-extract-plugin
export var bio = "about-module--bio--iFrfZ";
export var bioConnect = "about-module--bioConnect--8glTq";
export var bioConnectIcons = "about-module--bioConnectIcons--RKmrK";
export var bioConnectLabel = "about-module--bioConnectLabel--PTLdg";
export var bioContent = "about-module--bioContent--oioVF";
export var bioContentMain = "about-module--bioContentMain--GlJ4W";
export var bioContentMainBody = "about-module--bioContentMainBody--XsuUt";
export var bioContentSpacer = "about-module--bioContentSpacer--q4TXk";
export var bioContentSpacerTwo = "about-module--bioContentSpacerTwo--k66c2";
export var breakBox = "about-module--break-box--13KNf";
export var breakBoxFirst = "about-module--break-box-first--aKb6f";
export var breakBoxSecond = "about-module--break-box-second--PBVge";
export var bullets = "about-module--bullets--h+z2S";
export var columnToRow = "about-module--column-to-row--UhAZd";
export var container = "about-module--container--QdGxI";
export var containerBios = "about-module--containerBios--J60JU";
export var flex = "about-module--flex--njvFd";
export var image = "about-module--image--GEm0g";
export var newsletterSection = "about-module--newsletter-section--aczO6";
export var noNarrowScreen = "about-module--no-narrow-screen--CA7Xu";